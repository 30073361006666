import styles from "./shift-records-details-sales-inventory/shift-details-sales-inventory-dipstick.module.scss";
import grid from "styles/grid.module.scss";
import { InputAmount } from "components/field";

const DipstickInput = ({ unit, placeholder, fields, modifyField, disabled }) => {
  return (
    <>
      <div className={grid.row}>
        <div className={styles.columnFluid}>
          <InputAmount
            {...fields}
            decimalPlaces={3}
            innerLabel={false}
            className={styles.textfield}
            placeholder={placeholder}
            onChange={modifyField}
            disabled={disabled}
            inputDisabled={disabled}
            helperText={fields?.message || ""}
          />
        </div>
        <div className={styles.columnFluid}>
          <div className={styles.unit}>
            <div className={styles.text}>{unit}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DipstickInput;
