const PageKeysEnum = {
  DISCOUNT_REPORT: "DISCOUNT_REPORT",
  REFUND_TRANSACTION_REPORT: "REFUND_TRANSACTION_REPORT",
  SALES_SUMMARY_REPORT: "SALES_SUMMARY_REPORT",
  SALES_TRANSACTION_HISTORY_REPORT: "SALES_TRANSACTION_HISTORY_REPORT",
  SHIFT_RECORDS: "SHIFT_RECORDS",
  VOID_REPORT: "VOID_REPORT",
};

export default PageKeysEnum;
