import locale from "localization";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Title } from "components/commons";
import { nonSaleTransactionMapper } from "././shift-records-details-cash-non-cash.mapper";
import { nonSaleTransactionColumn } from "./shift-records-details-cash-non-cash-column";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import { sortBy } from "lodash";

const ShiftDetailsNonSales = ({ shift }) => {
  const { table } = useDataTable({
    value: sortBy(
      [
        ...shift?.transactionPaymentMethod.pumpCalibration,
        ...shift?.transactionPaymentMethod.pumpError,
      ],
      "transactionItemId"
    ).reverse(),
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "fuel",
      name: "nonSaleTransactions",
      mapper: (val) => nonSaleTransactionMapper({ val }),
      columns: nonSaleTransactionColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.nonSaleTransactions}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 {...table} hideNoSearchResultRender tableVersion="v2" hidePagination />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsNonSales;
