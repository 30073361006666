import locale from "localization";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Title } from "components/commons";
import { nonCashTransactionMapper } from "././shift-records-details-cash-non-cash.mapper";
import { nonCashTransactionColumn } from "./shift-records-details-cash-non-cash-column";
import { sortBy } from "lodash";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const ShiftDetailsNonCash = ({ shift }) => {
  const { table } = useDataTable({
    value: sortBy(shift?.transactionPaymentMethod.nonCash, "transactionItemId").reverse(),
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "nonCash",
      name: "nonCashTransactions",
      mapper: (val) => nonCashTransactionMapper({ val }),
      columns: nonCashTransactionColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.nonCashTransactions}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 {...table} hideNoSearchResultRender tableVersion="v2" hidePagination />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsNonCash;
